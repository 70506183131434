import AppBarComponent from "../../component/AppBar";
import './Header.scss';

export const HeaderLayout = () => {

  return (
    <header>
      <AppBarComponent/>
    </header>
  );
};